<template>
  <div class="v-data-table report bordered--table theme--light">
    <template v-for="item in list">
      <!-- <template v-if="filter.groupactivities ? item.projectnumber && item.projectname : true"> -->
        <v-simple-table class="report table table-bordered" v-if="type === 'all' || type === 'estimation'" :key="item.employeeid">
          <tbody>
            <tr style="background: #354759!important; color: #fff;">
              <td :colspan="headers.length"><strong>{{ $t('employee') }}: {{ item.employeename }}</strong></td>
            </tr>
          </tbody>
          <tbody v-for="project in item.projects" :key="project.projectid">
            <tr style="background: #5bc0de !important; color: #fff;" v-if="project.projectnumber || project.projectname ? timerFormSettings.project : true">
              <td :colspan="headers.length">
                <strong v-if="project.projectnumber || project.projectname">{{ $t('project') }}: {{ project.projectname || '' }}</strong>
                <strong v-else>{{ $t('absenceHours') }}</strong>
                <strong>{{ project.customername ? ' - ' + project.customername : '' }}</strong>
              </td>
            </tr>
            <tr v-if="project &&  project.hours && project.hours.length">
              <template v-for="(head, index) in headers">
                <td v-if="filter.groupactivities ? groupByActivitySupportedHeaders.includes(head.name) : true" :width="!filter.groupactivities ? head.width : 100 / 3 + '%'" :class="head.class" :key="index"><strong>{{ $t(head.text) }}</strong></td>
              </template>
            </tr>
            <tr v-for="hour in project.hours" :key="hour.id">
              <template v-for="(head, index) in headers">
                <td v-if="filter.groupactivities ? groupByActivitySupportedHeaders.includes(head.name) : true" :class="head.class" :key="index">
                  <!-- <template v-if="head.name === 'date'">{{ $formatter.formatDate(hour[head.name], 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') }}</template> -->
                  <!-- <template v-if="head.name === 'date'">{{ $formatter.formatDate(hour[head.name], 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY') }}</template> -->
                   <template v-if="head.name === 'date'"><span class="d-inline-block text-truncate">{{ $formatter.formatDate(hour[head.name], '', userDetails.dateformat) }} </span> </template>
                  <template v-else-if="head.name === 'hours'">{{ hour[head.name] - hour['breakhr'] | replaceDotWithComma }}</template>
                  <template v-else-if="['breakhr', 'toinvoice'].includes(head.name)">{{ hour[head.name] | replaceDotWithComma }}</template>
                  <!-- <template v-else-if="head.name === 'state'">{{ getStateName(hour[head.name]) }}</template> -->
                  <template v-else-if="head.name === 'startEndTime'">{{ hour.isabsence ? $t('notApplicableAbsence') : hour.start_date && hour.end_date ? $formatter.checkForIsSameDateTimeOrInvertedTimeRange(hour.start_date, hour.end_date) && $formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH:mm:ss', 'HH:mm') === '00:00' && hour.type === "working" ? $formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH:mm:ss', 'HH:mm') + ' - ' : getTime(hour) : '' }}</template>
                  <template v-else-if="head.name === 'worktype_name'"> {{ hour[head.name] ? `${hour.worktype_number || ''} - ${ hour[head.name] || ''}` : '' }} </template>
                  <template v-else>{{ hour[head.name] }}</template>
                </td>
              </template>
            </tr>
            <tr style="background: rgba(168, 191, 197, 0.62) !important;">
              <td :colspan="headers.length">
                <strong>{{ $t('total') }}: <strong>{{ $formatter.getSumBy(project.hours, x => x.hours) | replaceDotWithComma }}</strong></strong> &nbsp;&nbsp;&nbsp;
                <strong>{{ $t('totalToInvoice') }}: <strong> {{ $formatter.getSumBy(project.hours, x => x.toinvoice) | replaceDotWithComma }}</strong></strong>
              </td>
            </tr>
            <tr style="background: rgba(168, 191, 197, 0.62) !important;" v-if="type === 'estimation'">
              <td :colspan="headers.length">
              <strong>{{ $t('progress') }}: {{ project.progress || '0' | replaceDotWithComma }} %</strong> &nbsp;&nbsp;&nbsp;
              <strong class="float-right">{{ $t('estimation') }}: {{ project.estimationhrs | replaceDotWithComma }}</strong>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr style="background: rgb(255, 186, 192) !important;">
              <td :colspan="headers.length"><strong>{{ $t('total')  }}: {{ getProjectHoursTotal(item.projects) | replaceDotWithComma }}</strong></td>
            </tr>
            <tr style="background: rgb(255, 186, 192) !important;" v-if="type === 'estimation'">
              <td :colspan="headers.length">
              <strong>{{ $t('estimation') }}: {{ getProjectEstimation(item.projects) }}</strong> &nbsp;&nbsp;&nbsp;
              <strong class="float-right">{{ $t('progress') }}: {{ getProjectProgress(item.projects) | replaceDotWithComma }} %</strong>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      <!-- </template> -->
    </template>
    <v-simple-table class="report table table-bordered" v-if="type === 'sum'">
      <tbody v-for="item in list" :key="item.employeeid">
        <tr style="background: #354759!important; color: #fff;">
          <td colspan="2"><strong>{{ $t('employee') }}: {{ item.employeename }}</strong></td>
        </tr>
        <tr>
          <td width="50%"><strong>{{ $t('project') }}</strong></td>
          <td width="30%"><strong>{{ $t('total') }}</strong></td>
        </tr>
        <tr v-for="(project, i) in item.projects" :key="i">
          <td>
            <template v-if="project.projectid">{{ project.projectname || '' }} {{ project.customername ? ' - ' + project.customername : '' }}</template>
            <template v-else><b>{{ $t('absence') }}</b></template>
          </td>
          <td>{{ $formatter.getSumBy(project.hours, x => x.hours) - $formatter.getSumBy(project.hours, x => x.breakhr) | replaceDotWithComma }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-simple-table class="report table table-bordered" v-if="type === 'sum_both'">
       <tbody v-for="item in list" :key="item.customerid">
        <tr style="background: #354759!important; color: #fff;">
          <td colspan="4"><strong>{{ $t('employee') }}: {{ item.employeename || '' }} </strong></td><!-- {{ item.projectnumber || '' }} -->
        </tr>
        <tr>
          <td width="20%"><strong>{{ $t('project') }}</strong></td>
          <td width="20%"><strong>{{ $t('total') }}</strong></td>
          <td colspan="1"><strong>{{ $t('estimation') }} </strong></td>
          <td colspan="1"><strong>{{ $t('progress') }}</strong></td>
        </tr>
        <tr v-for="project in item.projects" :key="project.projectid">
          <td>
            <template v-if="project.projectid">{{ project.projectname }}</template>
            <template v-else><b>{{ $t('absence') }}</b></template>
          </td>
          <td> {{ $formatter.getSumBy(project.hours, x => x.hours) - $formatter.getSumBy(project.hours, x => x.breakhr) | replaceDotWithComma }}</td>
          <td>{{ project.estimationhrs }}</td>
          <td>{{ project.progress | replaceDotWithComma }} % </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: ['type', 'list', 'headers', 'filter', 'timerFormSettings'],
  filters: {
    replaceDotWithComma (value) {
      if (value === null || value === undefined || value === '') return ''
      return parseFloat(value).toFixed(2).toString().replace('.', ',')
    }
  },
  data () {
    return {
      groupByActivitySupportedHeaders: ['worktype_name', 'hours', 'toinvoice']
    }
  },
  methods: {
    getProjectHoursTotal (allProjects) {
      let total = 0
      if (allProjects && allProjects.length > 0) {
        for (let i = 0; i < allProjects.length; i++) {
          total += this.$formatter.getSumBy(allProjects[i].hours, x => x.hours)
        }
      }
      return total
    },
    getProjectEstimation (allProjects) {
      let total = 0
      if (allProjects && allProjects.length > 0) {
        for (let i = 0; i < allProjects.length; i++) {
          total += allProjects[i].estimationhrs
        }
      }
      return total
    },
    getProjectProgress (allProjects) {
      let total = 0
      if (allProjects && allProjects.length > 0) {
        for (let i = 0; i < allProjects.length; i++) {
          total += allProjects[i].progress
        }
      }
      return total
    },
    replaceDotWithComma (value) {
      if (value === null || value === undefined || value === '') return ''
      return parseFloat(value).toFixed(2).toString().replace('.', ',')
    }
  }
}
</script>
